<template>
    <div class="flex overflow-y-auto scroll-bar h-full flex-col divide-y border rounded-lg max-h-full">
        <div v-for="(per, id) in getPermissions" :key="per.name" class="flex flex-1">
            <div class="w-3/6 bg-gray-100 flex items-center px-3 py-4">
                <!-- <FormulateInput @change="(input) => selectAllPer(input, id, all=true)" :label="per.name" type="checkbox" class="font-semibold text-md text-gray-700" v-model="per.selected" :element-class="(context, classes) => ['mr-2'].concat(classes)" /> -->
                <div class="flex gap-2 items-center">
                    <input :disabled="disabled" type="checkbox" class="checkbox checkbox-sm checkbox-primary border-box-stroke" v-model="per.selected" @change="(input) => selectAllPer(input, id, (all = true), per)" :id="per.name + id" />
                    <label :for="per.name + id">{{ per.name }}</label>
                </div>
            </div>
            <div class="flex-1 px-3 py-4">
                <div class="flex flex-col gap-1 h-full justify-center">
                    <template v-for="(per_role, index) in per.permissions">
                        <!-- <FormulateInput @change="(input) => selectAllPer(input, id)" :key="per_role.permission_id" :label="per_role.name" class="text-gray-600" type="checkbox" v-model="per_role.selected" :element-class="(context, classes) => ['mr-2'].concat(classes)" /> -->
                        <div class="flex gap-2 items-center justify-between" :key="per_role.permission_id">
                            <div class="flex gap-2 items-center">
                                <input :disabled="disabled" type="checkbox" class="checkbox checkbox-sm checkbox-primary border-box-stroke" v-model="per_role.selected" @change="(input) => selectAllPer(input, id, (all = false), per_role)" :id="per_role.name + id + index" />
                                <label :for="per_role.name + id + index">{{ per_role.name }}</label>
                            </div>
                            <div v-if="per_role.description" v-tippy content="View role Permission info" @click="$emit('viewPermissionsInfo', per_role)" class="text-gray-600 cursor-pointer h-6 w-6 flex items-center justify-center rounded-full text-sm hover:bg-gray-200">
                                <font-awesome-icon icon="info-circle" class="text-primary" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "permissions-card",
    components: {},
    props: {
        Permissions: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            // permission dummy data
        };
    },
    computed: {
        getPermissions: {
            get() {
                return this.Permissions;
            },
            set(val) {
                this.Permissions = val;
            },
        },
    },
    methods: {
        selectAllPer(input, index, all = false, per) {
            this.$emit("selectAllPer", { input, index, all, per });
        },
    },
};
</script>

<style lang="scss" scoped>
.formulate-input {
    margin-bottom: 0;
}
</style>
